export default {
  get() {
    if ('undefined' === typeof localStorage) return []

    const favs = localStorage.getItem('favs')

    if (null === favs) {
      return []
    }

    return JSON.parse(favs)
  },
  has(uid) {
    return (
      this.get()
        .map((i) => i.uid)
        .indexOf(uid) > -1
    )
  },
  add({ uid, product }) {
    if ('undefined' === typeof localStorage) return

    if (this.has(uid)) {
      return
    }

    window.dataLayer.push({
      event: 'dd_klingenland_favs',
      item: uid,
    })

    localStorage.setItem(
      'favs',
      JSON.stringify([
        ...this.get(),
        {
          uid,
          product,
        },
      ])
    )
  },
  remove({ uid }) {
    if (!this.has(uid)) {
      return
    }

    localStorage.setItem(
      'favs',
      JSON.stringify(
        this.get().filter(function (item) {
          return item.uid !== uid
        })
      )
    )
  },
  toggle({ uid, product = null }) {
    if (this.has(uid)) {
      this.remove({ uid })
    } else {
      this.add({ uid, product })
    }
  },
}
