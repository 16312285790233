import React from 'react'
import tailwind from '../../../tailwind.config'
import Arrow from '../../assets/arrow.svg'
import slugify from '../../utils/slugify'
import useTexts from '../../utils/useTexts'

export const getVariations = (product) => {
  if (!product.data?.variations) {
    return []
  }
  return product.data.variations
    .filter((v) => v.id?.text)
    .map((v) => {
      v.id.text = slugify(v.id.text)
      return v
    })
}

export default ({ product, variation, setVariation }) => {
  const { dropdown_text } = useTexts()
  return (
    <label className="relative my-4 block w-makers">
      <select
        value={variation?.id.text ?? 0}
        onChange={(e) => {
          const selected = getVariations(product).find(
            (v) => String(v.id.text) === String(e.target.value)
          )
          if (selected) {
            setVariation(selected)
          }
        }}
        className="dd-input"
      >
        <option value="0">{dropdown_text}</option>
        {getVariations(product).map((v) => {
          return <option value={v.id.text}>{v.material_of_grip.text}</option>
        })}
      </select>
      <Arrow
        className="absolute pointer-events-none top-0 mt-5 right-0 mr-5 w-3 h-3"
        style={{
          fill: tailwind.theme.colors.gray[800],
          transform: 'rotate(90deg)',
        }}
      />
    </label>
  )
}
