module.exports = {
  purge: false,
  theme: {
    colors: {
      transparent: 'transparent',
      black: '#000000',
      white: '#ffffff',
      gray: {
        100: '#ededed',
        200: '#d4d4d4',
        300: '#a9a9a9',
        400: '#858585',
        500: '#',
        600: '#535351',
        700: '#',
        800: '#3f3f41',
        900: '#313131',
      },
      red: {
        200: '#FED7D7',
        300: '#FEB2B2',
        500: '#ce2c39',
        600: '#a92327',
      },
      green: {
        200: '#C6F6D5',
        300: '#9AE6B4',
        400: '#68D391',
        500: '#48BB78',
        600: '#38A169',
        700: '#2F855A',
        800: '#276749',
      },
      orange: {
        200: '#FEEBC8',
        700: '#C05621',
      },
    },
    borderRadius: {
      none: 0,
      default: '.3rem',
      full: '9999px',
    },
    extend: {
      screens: {
        '2xl': '1600px',
      },
      flex: {
        2: '2 2 0%',
      },
      maxWidth: {
        button: '16rem',
        makers: '20rem',
        grid: 1600,
        '7xl': '88rem',
      },
      minWidth: {
        button: '16rem',
        makers: '20rem',
        half: '50vw',
      },
      minHeight: {
        hero: '60vh',
      },
      maxHeight: {
        '11/12': '91.666666666667%',
      },
      width: {
        makers: '20rem',
        '96%': '96%',
      },
      zIndex: {
        '-10': '-10',
      },
      inset: {
        16: '4rem',
        '-4': '-1rem',
      },
      spacing: {
        7: '1.75rem',
        9: '2.25rem',
        18: '4.5rem',
        28: '7rem',
        36: '9rem',
      },
      fill: (theme) => ({
        red: theme('colors.white'),
        'red-hover': theme('colors.red.500'),
      }),
    },
  },
  variants: {
    fill: ['responsive', 'hover'],
  },
  plugins: [],
}
