import React, { useContext, useReducer } from 'react'
import { getVariations } from '../components/product/dropdown'
import { getVariationUid } from '../utils/getVariationUid'
import Cart from './cart'

export const CartContext = React.createContext({
  cart: [],
})

export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, {
    cart: Cart.get(),
  })

  return (
    <CartContext.Provider value={{ state, dispatch }} children={children} />
  )
}

const cartReducer = (state, action) => {
  switch (action.type) {
    case 'add':
      Cart.add(action.payload)
      return { cart: Cart.get() }

    case 'remove':
      Cart.remove(action.payload)
      return { cart: Cart.get() }

    default:
      return state
  }
}

export default () => {
  const { dispatch } = useContext(CartContext)

  const isInCart = (uid) => {
    return Cart.has(uid)
  }

  const sizeOfCart = Cart.get().reduce((sum, item) => {
    return sum + item.amount
  }, 0)

  const addToCart = (product, amount = 1, variation = null) => {
    let v = product.variation ?? variation
    if (!v && getVariations(product).length > 0) {
      v = product.data.variations[0]
    }

    dispatch({
      type: 'add',
      payload: {
        uid: getVariationUid(product, v),
        product: {
          ...product,
          variation: v,
        },
        amount,
      },
    })
  }

  const removeFromCart = (uid) => {
    dispatch({
      type: 'remove',
      payload: {
        uid,
      },
    })
  }

  return {
    cart: Cart.get(),
    isInCart,
    sizeOfCart,
    addToCart,
    removeFromCart,
  }
}
