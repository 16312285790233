import { graphql, useStaticQuery } from 'gatsby'
import prismic from './prismic'
import useLang from './useLang'

export default () => {
  const { lang } = useLang()

  const data = useStaticQuery(graphql`
    query Texts {
      allPrismicTranslations {
        nodes {
          lang
          data {
            email_address {
              text
            }
            facebook {
              text
            }
            instagram {
              text
            }

            order_received {
              text
            }
            contact_message_label {
              text
            }
            cookie_notice_text {
              text
            }
            cookie_notice_button {
              text
            }
            cookie_notice_don_t_track_button {
              text
            }

            header_link_home {
              text
            }
            header_link_products {
              text
            }
            header_link_makers {
              text
            }
            header_link_courses {
              text
            }
            header_link_favorites {
              text
            }
            header_link_cart {
              text
            }

            footer_newsletter_title {
              text
            }
            footer_newsletter_text {
              text
            }
            footer_newsletter_button {
              text
            }

            hero_button_text {
              text
            }
            products_title {
              text
            }
            products_button_text {
              text
            }
            makers_title {
              text
            }
            makers_button_text {
              text
            }

            product_card_button_text {
              text
            }
            favorite_button_mouseover {
              text
            }
            product_popup_button_text {
              text
            }
            product_availability_available {
              text
            }
            product_availability_sold_out {
              text
            }
            product_availability_on_order {
              text
            }
            product_category_label {
              text
            }
            product_full_length_label {
              text
            }
            product_length_of_blade_label {
              text
            }
            product_thickness_label {
              text
            }
            product_material_of_blade_label {
              text
            }
            product_material_of_grip_label {
              text
            }
            product_usage_label {
              text
            }
            product_preis_label {
              text
            }
            product_price_missing {
              text
            }
            dropdown_text {
              text
            }

            filter_search_placeholder {
              text
            }
            filter_select_all {
              text
            }
            filter_number_of_visible {
              text
            }
            filter_button {
              text
            }
            filter_over_100mm {
              text
            }
            filter_under_100mm {
              text
            }
            filter_label_edc {
              text
            }
            sorting_name_az {
              text
            }
            sorting_name_za {
              text
            }
            sorting_price_inc {
              text
            }
            sorting_price_dec {
              text
            }

            favorites_empty_text {
              text
            }
            favorites_button {
              text
            }
            cart_empty_text {
              text
            }
            cart_back_button {
              text
            }
            cart_order_button {
              text
            }

            course_card_button {
              text
            }
            course_form_button {
              text
            }
            news_card_button {
              text
            }
            knowledge_card_button {
              text
            }
            contact_form_button {
              text
            }
            page_404_title {
              text
            }
            page_404_text {
              text
            }
            page_404_button {
              text
            }

            form_title {
              text
            }
            form_label_anrede {
              text
            }
            form_anrede_options {
              text {
                text
              }
            }
            form_label_first_name {
              text
            }
            form_label_last_name {
              text
            }
            form_label_street {
              text
            }
            form_label_country {
              text
            }
            form_country_options {
              text {
                text
              }
            }
            form_label_plz {
              text
            }
            form_label_city {
              text
            }
            form_label_phone {
              text
            }
            form_label_email {
              text
            }
            form_label_notes {
              text
            }
            form_label_newsletter {
              text
            }
            form_newsletter_text {
              text
            }
            form_button {
              text
            }
            map_state {
              text
            }
            map_number_of_visible {
              text
            }
          }
        }
      }
    }
  `)

  const nodes = data.allPrismicTranslations.nodes
  const node = nodes.find((node) => node.lang === lang)
  const values = node ? node.data : nodes[0].data

  return Object.keys(values)
    .map((key) => {
      if (key.indexOf('options') > -1) {
        return {
          key,
          value: Object.values(values[key]).map((value) => {
            return prismic(value.text)
          }),
        }
      }

      return {
        key,
        value: prismic(values[key]),
      }
    })
    .reduce((sum, item) => {
      sum[item.key] = item.value
      return sum
    }, {})
}
