import React, { useContext, useReducer } from 'react'
import { getVariationUid } from '../utils/getVariationUid'
import Favs from './favs'

export const FavsContext = React.createContext({
  favs: [],
})

export const FavsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(favsReducer, {
    favs: Favs.get(),
  })

  return (
    <FavsContext.Provider value={{ state, dispatch }} children={children} />
  )
}

const favsReducer = (state, action) => {
  switch (action.type) {
    case 'add':
      Favs.add(action.payload)
      return { favs: Favs.get() }

    case 'remove':
      Favs.remove(action.payload)
      return { favs: Favs.get() }

    case 'toggle':
      Favs.toggle(action.payload)
      return { favs: Favs.get() }

    default:
      return state
  }
}

export default () => {
  const { dispatch } = useContext(FavsContext)

  const isInFavs = (uid) => {
    return Favs.has(uid)
  }

  const sizeOfFavs = Favs.get().length

  const addToFavs = (product, variation = null) => {
    const v = product.variation ?? variation

    dispatch({
      type: 'add',
      payload: {
        uid: getVariationUid(product, v),
        product: {
          ...product,
          variation: v,
        },
      },
    })
  }

  const removeFromFavs = (uid) => {
    dispatch({
      type: 'remove',
      payload: {
        uid,
      },
    })
  }

  const toggleFavs = (product, variation = null) => {
    const v = product.variation ?? variation

    dispatch({
      type: 'toggle',
      payload: {
        uid: getVariationUid(product, v),
        product: {
          ...product,
          variation: v,
        },
      },
    })
  }

  return {
    favs: Favs.get(),
    isInFavs,
    sizeOfFavs,
    addToFavs,
    removeFromFavs,
    toggleFavs,
  }
}
