export default {
  get() {
    if ('undefined' === typeof localStorage) return []

    const cart = localStorage.getItem('cart')

    if (null === cart) {
      return []
    }

    return JSON.parse(cart)
  },
  has(uid) {
    return (
      this.get()
        .map((i) => i.uid)
        .indexOf(uid) > -1
    )
  },
  find(uid) {
    return this.get().find((i) => i.uid === uid)
  },
  add({ uid, product, amount }) {
    if ('undefined' === typeof localStorage) return

    const number = parseInt(amount, 10)

    window.dataLayer.push({
      event: 'dd_klingenland_cart',
      item: uid,
      amount: number,
    })

    if (!this.has(uid)) {
      localStorage.setItem(
        'cart',
        JSON.stringify([
          ...this.get(),
          {
            uid,
            product,
            amount: number,
          },
        ])
      )
    } else {
      if (this.find(uid).amount + number < 1) {
        this.remove({ uid })
        return
      }

      localStorage.setItem(
        'cart',
        JSON.stringify(
          this.get().map((i) => {
            if (i.uid !== uid) return i

            return {
              uid,
              product,
              amount: this.find(uid).amount + number,
            }
          })
        )
      )
    }
  },
  remove({ uid }) {
    if (!this.has(uid)) {
      return
    }

    localStorage.setItem(
      'cart',
      JSON.stringify(
        this.get().filter(function (item) {
          return item.uid !== uid
        })
      )
    )
  },
}
