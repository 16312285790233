exports.components = {
  "component---src-list-products-js": () => import("./../../../src/list/products.js" /* webpackChunkName: "component---src-list-products-js" */),
  "component---src-pages-404-de-js": () => import("./../../../src/pages/404.de.js" /* webpackChunkName: "component---src-pages-404-de-js" */),
  "component---src-pages-404-en-js": () => import("./../../../src/pages/404.en.js" /* webpackChunkName: "component---src-pages-404-en-js" */),
  "component---src-pages-danke-de-js": () => import("./../../../src/pages/danke.de.js" /* webpackChunkName: "component---src-pages-danke-de-js" */),
  "component---src-pages-danke-en-js": () => import("./../../../src/pages/danke.en.js" /* webpackChunkName: "component---src-pages-danke-en-js" */),
  "component---src-pages-einkaufsliste-[section]-js": () => import("./../../../src/pages/einkaufsliste/[section].js" /* webpackChunkName: "component---src-pages-einkaufsliste-[section]-js" */),
  "component---src-pages-einkaufsliste-index-js": () => import("./../../../src/pages/einkaufsliste/index.js" /* webpackChunkName: "component---src-pages-einkaufsliste-index-js" */),
  "component---src-pages-en-einkaufsliste-[section]-js": () => import("./../../../src/pages/en/einkaufsliste/[section].js" /* webpackChunkName: "component---src-pages-en-einkaufsliste-[section]-js" */),
  "component---src-pages-en-einkaufsliste-index-js": () => import("./../../../src/pages/en/einkaufsliste/index.js" /* webpackChunkName: "component---src-pages-en-einkaufsliste-index-js" */),
  "component---src-pages-index-de-js": () => import("./../../../src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-merkliste-de-js": () => import("./../../../src/pages/merkliste.de.js" /* webpackChunkName: "component---src-pages-merkliste-de-js" */),
  "component---src-pages-merkliste-en-js": () => import("./../../../src/pages/merkliste.en.js" /* webpackChunkName: "component---src-pages-merkliste-en-js" */),
  "component---src-pages-schmiede-messermacher-de-js": () => import("./../../../src/pages/schmiede-messermacher.de.js" /* webpackChunkName: "component---src-pages-schmiede-messermacher-de-js" */),
  "component---src-pages-schmiede-messermacher-en-js": () => import("./../../../src/pages/schmiede-messermacher.en.js" /* webpackChunkName: "component---src-pages-schmiede-messermacher-en-js" */),
  "component---src-pages-schmiedekarte-de-js": () => import("./../../../src/pages/schmiedekarte.de.js" /* webpackChunkName: "component---src-pages-schmiedekarte-de-js" */),
  "component---src-pages-schmiedekarte-en-js": () => import("./../../../src/pages/schmiedekarte.en.js" /* webpackChunkName: "component---src-pages-schmiedekarte-en-js" */),
  "component---src-single-course-js": () => import("./../../../src/single/course.js" /* webpackChunkName: "component---src-single-course-js" */),
  "component---src-single-knowledge-js": () => import("./../../../src/single/knowledge.js" /* webpackChunkName: "component---src-single-knowledge-js" */),
  "component---src-single-maker-js": () => import("./../../../src/single/maker.js" /* webpackChunkName: "component---src-single-maker-js" */),
  "component---src-single-news-js": () => import("./../../../src/single/news.js" /* webpackChunkName: "component---src-single-news-js" */),
  "component---src-single-page-js": () => import("./../../../src/single/page.js" /* webpackChunkName: "component---src-single-page-js" */),
  "component---src-single-product-js": () => import("./../../../src/single/product.js" /* webpackChunkName: "component---src-single-product-js" */)
}

