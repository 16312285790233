import React, { useContext, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import useInternalLink from '../utils/useInternalLink'

const Context = React.createContext()

export function ModalProvider({ children }) {
  const modalRef = useRef()
  const [context, setContext] = useState()

  useEffect(() => {
    setContext(modalRef.current)
  }, [])

  return (
    <div>
      <Context.Provider value={context}>{children}</Context.Provider>
      <div ref={modalRef} />
    </div>
  )
}

const addTrailingSlash = (str) => {
  return str.endsWith('/') ? str : str + '/'
}

export default ({ onClose, children, path, ...props }) => {
  const modalNode = useContext(Context)
  const listPageLink = useInternalLink('produkte')

  useEffect(() => {
    if ('undefined' === typeof window) return
    if (!path) return

    let url = addTrailingSlash(window.location.pathname)
    const realPath = addTrailingSlash(path)

    if (url === realPath) url = listPageLink

    document.body.style.overflow = 'hidden'
    window.history.pushState({}, null, realPath)

    if (
      'undefined' !== typeof localStorage &&
      !localStorage.getItem('doNotTrack')
    ) {
      window.dataLayer.push({ event: 'dd_klingenland_start' })
    }

    return () => {
      document.body.style.overflow = 'auto'
      if (realPath === addTrailingSlash(window.location.pathname)) {
        window.history.pushState({}, null, url)

        if (
          'undefined' !== typeof localStorage &&
          !localStorage.getItem('doNotTrack')
        ) {
          window.dataLayer.push({ event: 'dd_klingenland_start' })
        }
      }
    }
  }, [path])

  if (!modalNode) {
    return null
  }

  const elems = (
    <div className="z-30 fixed overflow-y-auto inset-0 dd-center">
      <div
        onClick={onClose}
        style={{
          background: 'rgba(0,0,0,.5)',
        }}
        className="fixed inset-0"
      ></div>
      <div
        style={{
          position: 'relative',
        }}
        {...props}
      >
        {children}
      </div>
    </div>
  )

  return ReactDOM.createPortal(elems, modalNode)
}
