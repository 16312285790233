module.exports = (doc) => {
  let lang = ''
  if (doc.lang === 'en-us') {
    lang = '/en'
  }

  switch (doc.type) {
    case 'product':
      return lang + '/produkt/' + doc.uid + '/'

    case 'maker':
      return lang + '/schmied/' + doc.uid + '/'

    case 'page':
      if ('home' === doc.uid) return lang + '/'
      return lang + '/' + doc.uid + '/'

    case 'knowledge':
      return lang + '/' + doc.uid + '/'

    case 'news':
      return lang + '/news/' + doc.uid + '/'

    case 'course':
      return lang + '/kurs/' + doc.uid + '/'

    default:
      return lang + '/'
  }
}
