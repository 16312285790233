import React, { useContext, useState } from 'react'

const Context = React.createContext({})

export const LangProvider = ({ children }) => {
  const [lang, setLang] = useState('de-at')

  return <Context.Provider value={{ lang, setLang }} children={children} />
}

export default () => {
  const { lang, setLang } = useContext(Context)

  return { lang, setLang }
}
